	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/10/2020 08:36
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-heart:before { content: "\f100"; }
.flaticon-like:before { content: "\f101"; }
.flaticon-heart-1:before { content: "\f102"; }
.flaticon-shopping-cart:before { content: "\f103"; }
.flaticon-right-quote:before { content: "\f104"; }
.flaticon-call:before { content: "\f105"; }
.flaticon-telephone:before { content: "\f106"; }
.flaticon-email:before { content: "\f107"; }
.flaticon-message:before { content: "\f108"; }
.flaticon-paper-plane:before { content: "\f109"; }
.flaticon-security:before { content: "\f10a"; }
.flaticon-box:before { content: "\f10b"; }
.flaticon-package:before { content: "\f10c"; }
.flaticon-facebook:before { content: "\f10d"; }
.flaticon-twitter:before { content: "\f10e"; }
.flaticon-instagram:before { content: "\f10f"; }
.flaticon-headphones:before { content: "\f110"; }
.flaticon-car-insurance:before { content: "\f111"; }
.flaticon-medical-insurance:before { content: "\f112"; }
.flaticon-life-insurance:before { content: "\f113"; }
.flaticon-health-insurance:before { content: "\f114"; }
.flaticon-medical-insurance-1:before { content: "\f115"; }
.flaticon-home-insurance:before { content: "\f116"; }
.flaticon-briefcase:before { content: "\f117"; }
.flaticon-briefcase-1:before { content: "\f118"; }
.flaticon-medical-insurance-2:before { content: "\f119"; }
.flaticon-magnifying-glass:before { content: "\f11a"; }
.flaticon-consulting:before { content: "\f11b"; }
.flaticon-consult:before { content: "\f11c"; }
.flaticon-technical-support:before { content: "\f11d"; }
.flaticon-technical-support-1:before { content: "\f11e"; }
.flaticon-customer-support:before { content: "\f11f"; }
.flaticon-bar-chart:before { content: "\f120"; }
.flaticon-heart-2:before { content: "\f121"; }
.flaticon-steering-wheel:before { content: "\f122"; }
.flaticon-insurance:before { content: "\f123"; }
.flaticon-home-insurance-1:before { content: "\f124"; }
.flaticon-health-insurance-1:before { content: "\f125"; }
