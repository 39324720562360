button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.slick-slider .slick-track, .slick-slider .slick-list{
    display: flex;
}
.banner-3.sigma_banner .slick-slide{
    display: block;
}
.map-marker > span + img{
    display: none;
}
.sigma_portfolio-item.style-3 .sigma_portfolio-item-content-inner>a:after, 
.sigma_portfolio-item.style-2 .sigma_portfolio-item-content-inner>a:after,
.sigma_post-meta a:after{
    content: ', ';
}
.sigma_post-meta a:last-child::after{
    content: '';
}
.banner-cta.sigma_box .sigma_box-content > i{
    cursor: text;
}
.accordion .card .card-header {
    padding: 0;
    border-radius: 0;
    border: none;
}
.accordion .card .card-header .btn{
    border: none;
    width: 100%;
    border-radius: 0;
    text-transform: capitalize;
}
.card .card-header{
    display: flex;
    flex-basis: 100%;
    order: 1;
    border-radius: 0;
}
.card .collapseparent{
    order: 2;
}
.accordion .card .collapseparent.show + .card-header .btn{
    background-color: #9ecf36;
    color: #fff;
}
.accordion .card .collapseparent.show + .card-header .btn::after, 
.accordion .card .collapseparent.show + .card-header .btn::before {
    background-color: #fff;
}
.accordion .card .collapseparent.show + .card-header .btn::before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 0;
}
.widget-recent-posts .sigma_recent-post>a{
    height: 75px;
}
.widget-recent-posts .sigma_recent-post>a>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sidebar-style-two .widget-search,
.sidebar-style-two .widget-ad,
.post-detail-wrapper .sigma_post-meta a:after,
.sigma_post-list .sigma_post-meta a:after,
.sigma_post-categories a::after,
.sidebar-style-one .widget-categories,
.sidebar-style-one .widget-ad,
.sidebar-style-one .widget-ig{
    display: none;
}
.sidebar-style-two .sidebar{
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
}
.sidebar-style-two .sidebar .sidebar-widget{
    order: 2;
    flex-basis: 100%;
}
.sidebar-style-two .sidebar .widget-categories{
    order: 1;
    margin-top: 0;
}
.sigma_post.post-format-gallery .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    opacity: 1;
    visibility: visible;
    font-size: 0;
    border: none;
    cursor: pointer;
}
.sigma_post.post-format-gallery .slick-arrow::before{
    font-size: 14px;
}
.sigma_post.post-format-gallery .slick-arrow.slick-prev{
    left: 0;
}
.sigma_post.post-format-gallery .slick-arrow.slick-next{
    right: 0;
}
.sigma_product-controls a.active{
    background-color: #9ecf36;
    color: #fff;
    box-shadow: 0px 10px 50px 0px rgb(53 82 99 / 9%);
}
.sigma_post.post-format-quote{
    background-image: url(assets/img/textures/blockquote.png);
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
    background-color: #9ecf36!important;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #9ecf36!important;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #9ecf36!important;
}
.relative {
    position: relative;
}
.error-texts h1 {
    font-size: 133px;
    font-weight: 900;
    color: #85c226;
    margin-bottom: 20px;
}
.error-texts h3{
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 20px;
}
.error-texts p{
    font-size: 16px;
    margin-bottom: 20px;
}
.alert.d-block{
    display: block!important;
}